import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import emailjs from 'emailjs-com';
import Field from './Field';
import Title from './Title';
import Button from './Button';
import { store } from '../store/store';
import Video from './Video';

const StyledTitle = styled(Title)`
  font-size: 3.5rem;
  margin: 0 0 1rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 4rem;
  }

  + p {
    @media (${({ theme }) => theme.respondTo.mobile}) {
      font-size: 1.6rem;
    }
  }
`;

const StyledForm = styled.form`
  margin: 2.4rem 0 0;
  width: 100%;
`;

const SuccesWrapper = styled.div`
  margin: 0 auto;
  text-align: center;

  h2 {
    margin: 1.5rem 0;
    color: ${({ theme }) => theme.primaryColor};
  }

  p {
    margin-top: 2rem;
    @media (${({ theme }) => theme.respondTo.mobile}) {
      font-size: 1.6rem;
    }
    a {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    }
  }
`;

const Form = () => {
  const { state } = useContext(store);
  const [isClicked, setIsClicked] = useState(false);
  const [sent, setSent] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    setIsClicked(true);

    const { company, name, email } = e.target;

    const data = {
      company: company.value,
      name: name.value,
      email: email.value,
      subdomain: state.subdomain,
    };

    emailjs
      .send('sendgrid', 'template_VcsPmwfi', data, 'user_U3UgeGGndUknGCBIqwymY')
      .then(
        result => {
          setSent(true);
        },
        error => {
          setErrors(error.text);
          setIsClicked(false);
        }
      );
  };

  return (
    <Modal trigger={state.modalIsOpen}>
      {!sent ? (
        <>
          <StyledTitle>Bijna klaar!</StyledTitle>
          <p>
            Nog enkele laatste gegevens. Zo leren we je beter kennen, en kunnen
            we het document voor je teksten, producten & foto's naar je
            e-mailadres sturen.
          </p>
          <StyledForm onSubmit={e => handleSubmit(e)}>
            <Field>
              <input required type="text" name="name" />
              <label htmlFor="name">Naam</label>
            </Field>
            <Field>
              <input required type="text" name="company" />
              <label htmlFor="company">Bedrijfsnaam</label>
            </Field>
            <Field>
              <input required type="email" name="email" />
              <label htmlFor="email">E-mail</label>
            </Field>
            <Button disabled={isClicked} big type="submit">
              {isClicked ? 'Versturen...' : 'Aanmelden'}
            </Button>
            {errors && (
              <p style={{ color: 'red', marginTop: '1rem' }}>{errors}</p>
            )}
          </StyledForm>
        </>
      ) : (
        <>
          <SuccesWrapper>
            <Video />
            <StyledTitle>Verstuurd!</StyledTitle>
            <h3>
              Snel terug naar oude tijden,
              <br /> laten we hopen.
            </h3>
            <p>
              En oja, lokaly.shop draait volledig op donaties. Dus als je blij
              bent met onze dienst en dit project wilt steunen, dat kan! Doneren
              is mogelijk via{' '}
              <a
                target="_blank"
                rel="noopener nofollow"
                href="https://tikkie.me/pay/uhopndeuuclqkh97d0op"
              >
                deze Tikkie link
              </a>
            </p>
          </SuccesWrapper>
        </>
      )}
    </Modal>
  );
};

export default Form;
