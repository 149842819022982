import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import SEO from './Seo';
import GlobalStyle from '../styling/GlobalStyle';
import theme from '../styling/theme';
import Header from './Header';
import SignUpModal from './SignUpModal';

const Wrapper = styled.div`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`;

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const Layout = ({ children, seo }) => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <GlobalStyle />
        <SEO {...seo} />
        <Header />
        <Main>{children}</Main>
        <SignUpModal />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Layout;
