import styled from 'styled-components';
import { darken } from 'polished';

const Button = styled.button`
  appearance: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 1.25rem 1.5rem;
  color: white;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fontFamilyHeading};
  width: ${({ big }) => big && '100%'};

  &:disabled {
    background-color: lightgray;
    cursor: not-allowed;

    &:hover {
      background-color: lightgray;
    }
  }

  &:hover {
    background-color: ${({ theme }) => darken('0.05', theme.primaryColor)};
  }
`;

export default Button;
