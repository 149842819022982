const theme = {
  // Colours
  primaryColor: '#18AAB2',
  textColor: '#000',

  // Fonts
  fontSize: '1.8rem',
  fontFamily:
    '"Affogato Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeading:
    '"Affogato Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeadingBold:
    '"BerlingskeSerif Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: '1160px',

  // Breakpoints
  //media queries
  respondTo: {
    desktopExtraBig: `min-width: 1600px`,
    desktopSuperBig: `min-width: 1400px`,
    desktopBig: `min-width: 1280px`,
    desktop: `min-width: 1024px`,
    tablet: `min-width: 481px`,
    mobile: `max-width: 480px`,
    iphone5: `max-width: 320px`,
  },
};

export default theme;
