import React from 'react';
import Helmet from 'react-helmet';
import ShareImage from '../../static/images/share.jpg';
import Favicon from '../../static/images/favicon.png';

const SEO = ({ description, lang = 'nl', title }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[{ rel: 'icon', type: 'image/png', href: Favicon }]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: ShareImage,
        },
        {
          property: `image`,
          content: ShareImage,
        },
      ]}
    />
  );
};

export default SEO;
