import { createGlobalStyle } from 'styled-components';
import BerlingskeSerifBoldWoff2 from '../../static/fonts/BerlingskeSerif-Bold.woff2';
import BerlingskeSerifBoldWoff from '../../static/fonts/BerlingskeSerif-Bold.woff';
import AffogatoMediumWoff from '../../static/fonts/Affogato-Medium.woff';
import AffogatoMediumWoff2 from '../../static/fonts/Affogato-Medium.woff2';
import AffogatoRegularWoff from '../../static/fonts/Affogato-Regular.woff';
import AffogatoRegularWoff2 from '../../static/fonts/Affogato-Regular.woff2';

// set basic styling and 'normalize' things
const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'BerlingskeSerif Bold';
    src: url(${BerlingskeSerifBoldWoff}) format('woff'),
      url(${BerlingskeSerifBoldWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Affogato Medium';
    src: url(${AffogatoMediumWoff}) format('woff'),
      url(${AffogatoMediumWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Affogato Regular';
    src: url(${AffogatoRegularWoff}) format('woff'),
      url(${AffogatoRegularWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    overflow: auto;
    font-size: 1.8em;
    font-family: ${({ theme }) => theme.fontFamily};
    background-color: #FFF;
    color: ${({ theme }) => theme.textColor};
    &:after {
      /* content: ""; */
      position: absolute;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      background-image: url('https://assets.website-files.com/5bfd1275cc56e15ce750b18e/5c289afb9a15757600893ab7_78.%20Night%20sky.jpg');
      background-size: cover;
      opacity: 0.8;
      z-index: -1;
      transform: scale(-1);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilyHeadingBold};
    font-weight: normal;
    line-height: 1;
  }

  p {
    margin: 0;
    padding: 0;

    + p {
      margin-top: 1.5rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    font-family: ${({ theme }) => theme.font};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  input {
    /* font-family: ${({ theme }) => theme.fontFamily}; */
  }
`;

export default GlobalStyle;
