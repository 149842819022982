import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 0;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
  border: 1px solid transparent;
  margin: 0 auto;
  width: 14rem;
  padding-bottom: 14rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 19rem;
    padding-bottom: 19rem;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`;

const Video = () => {
  return (
    <Wrapper>
      <video
        id="gif-mp4"
        poster="https://media.giphy.com/media/12gcFJY9LEi6zu/200_s.gif"
        width="500"
        height="374"
        autoPlay="autoplay"
        loop="loop"
        playsInline
        controls={false}
      >
        <source
          src="https://media.giphy.com/media/12gcFJY9LEi6zu/giphy.mp4"
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
        <img
          src="https://media.giphy.com/media/12gcFJY9LEi6zu/giphy.gif"
          title="Your browser does not support the mp4 video codec."
        />
      </video>
    </Wrapper>
  );
};

export default Video;
