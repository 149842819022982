import styled from 'styled-components';

const Button = styled.h2`
  color: ${({ theme, primaryColor }) =>
    primaryColor ? theme.primaryColor : theme.textColor};
  font-size: ${({ big }) => (big ? '2.6rem' : '2.2rem')};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: ${({ big }) => (big ? '3.4rem' : '2.8rem')};
  }

  @media (${({ theme }) => theme.respondTo.iphone5}) {
    font-size: ${({ big }) => (big ? '2.4rem' : '2.4rem')};
  }
`;

export default Button;
