import React, { useContext } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Container from './Container';
import { store } from '../store/store';

const Wrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3rem 0;
  z-index: 1;
`;

const Nav = styled.ul`
  display: flex;
  list-style: none;
`;

const NavItem = styled.li`
  font-size: 1.7rem;
  margin: 0 0 0 1.5rem;
  font-family: ${({ theme }) => theme.fontFamilyHeading};
  border-bottom: 2px solid;
  border-color: ${({ active }) => (active ? 'black' : 'transparent')};
  cursor: pointer;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 2.25rem;
    margin: 0 0 0 4rem;
  }

  @media (${({ theme }) => theme.respondTo.iphone5}) {
    font-size: 1.5rem;
    margin: 0 0 0 1rem;
  }

  &:first-child {
    margin: 0 auto 0 0;
    color: inherit;
  }
`;

const Header = () => {
  const { state, dispatch } = useContext(store);
  const isLarge = useMediaQuery('(min-width: 48rem)');

  const handleClick = () => {
    if (isLarge) {
      dispatch({ type: 'TOGGLE_HOW_IT_WORKS' });
    } else {
      dispatch({ type: 'TOGGLE_SHOULD_SCROLL', data: true });
      setTimeout(() => {
        dispatch({ type: 'TOGGLE_SHOULD_SCROLL', data: false });
      }, 1000);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Nav>
          <NavItem>
            <Link to="/">lokaly.shop</Link>
          </NavItem>
          <NavItem
            active={state.howItWorksIsOpen}
            onClick={() => handleClick()}
          >
            <Link to="/">Hoe het werkt</Link>
          </NavItem>
          {isLarge && (
            <NavItem>
              <Link to="/faq/">FAQ</Link>
            </NavItem>
          )}
          <NavItem>
            <a
              href="https://limburgia-assen.lokaly.shop"
              target="_blank"
              rel="noopener nofollow"
            >
              Voorbeeld
            </a>
          </NavItem>
        </Nav>
      </Container>
    </Wrapper>
  );
};

export default Header;
